// USER LOGIN

.login__wrap{
    min-height: 100vh;
    height: max-content;
    background-color: #B5B1B0;
    background: linear-gradient(143.01deg, #0E2A47e7 19.49%, #301934e7 50.13%, #000822cb 83.44%), url('../../images/login-bg.jpg');
    background-blend-mode: multiply, normal;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 50%;
    padding-left: 0;
    position: relative;

    .login__side{
        max-width: 100%;
        position: relative;
        width: 1024px;
        min-height: 100vh;
        height: max-content;
        background-color: #fff !important;
        padding-top: 4rem;
        padding-bottom: 4rem;
        z-index: 1;
        margin-left: auto;
        margin-right: 0;
        display: flex;
        padding-left: 1rem;
        padding-right: 1rem;
        
        .login__cont{
            .login__footer{
                margin-top: 4rem;
                @media screen and (max-width: 1080px) {
                    margin-top: 0.5rem;
                }
                button.login__new{
                    background-color: #fff;
                    border: 1px solid $stroke1;
                    font-size: 14px;
                    color: $dark1;
                    margin-bottom: 1.5rem;
                    box-shadow: 0px 2px 4px -2px rgba(27, 46, 94, 0.08);
                    border-radius: 6px;
                    span{
                        margin-left: 0.75rem;
                        color: $blue1;
                        font-weight: 600;
                        transition: all 0.1s;
                    }
                    transition: all 0.1s;
                    &:hover{
                        background-color: $blue1;
                        color: #fff;
                        span{
                            color: #fff;
                        }
                    }
                }
                .login__footer__bottom{
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 1080px) {
                        justify-content: center;
                    }
                    .__dec{
                        width: 4px;
                        height: 4px;
                        border-radius: 100%;
                        background-color: $dark6;
                        margin-left: 0.75rem;
                        margin-right: 0.75rem;
                    }
                    a{
                        font-weight: 500;
                        font-size: 14px;
                        color: $dark6;
                        line-height: 14px;
                        transition: all 0.1s;
                        &:hover{
                            color: $blue1;
                        }
                    }
                }
            }
            width: 475px;
            min-height: 100%;
            height: max-content;
            max-width: 100%;
            margin: auto;
            padding-left: 1rem;
            padding-right: 1rem;
            @media screen and (max-width: 1080px) {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                min-height: auto;
            }
            h1{
                font-size: 28px;
                margin-bottom: 2.5rem;
                color: $black1;
                font-weight: 600;
                @media screen and (max-width: 1080px) {
                    font-size: 22px;
                    margin-bottom: 2rem;
                    text-align: center;
                }
            }
            .login__google{
                transition: all 0.1s;
                width: 100%;
                border: none;
                background-color: $offwhite;
                padding-top: 1.9rem;
                padding-bottom: 1.9rem;
                border-radius: 15px;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 1.5rem;
                img{
                    margin-right: 0.75rem;
                    width: 22.5px;
                }
                &:hover{
                    background-color: $blue1;
                    color: #fff;
                }
            }
            .login__divider{
                position: relative;
                margin-top: 1rem;
                margin-bottom: 2rem;
                text-align: center;
                @media screen and (max-width: 1080px) {
                    margin-top: 0.25rem;
                    margin-bottom: 1.75rem;
                }
                p{
                    position: relative;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    margin-left: auto;
                    margin-right: auto;
                    width: max-content;
                    background-color: #fff;
                    color: $dark1;
                    font-size: 14px;
                    font-weight: 500;
                }
                &::before{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    transform: translateY(-50%);
                    height: 1px;
                    background-color: $stroke1;
                }
            }
            .login__image{
                width: 122px;
                margin-bottom: 1rem;
            }
            .login__form{
                @media screen and (max-width: 1080px) {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
                button.login__submit{
                    border: 1px solid $green3;
                    width: 100%;
                    background-color: $green2;
                    padding-top: 1.15rem;
                    padding-bottom: 1.15rem;
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 2rem;
                    color: #fff;
                    cursor: pointer;
                    &:hover{
                        background-color: $blue1;
                    }
                }
                .login__error{
                    background-color: $red_dark;
                    padding: 1rem;
                    font-size: 12px;
                    color: #fff;
                    margin-bottom: 1rem;
                    margin-top: 0rem;
                    border-radius: 8px;
                }
                .login__remember{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    p{
                        font-size: 14px;
                        color: $dark3;
                    }
                    a{
                        p{
                            color: $dark3;
                            &:hover{
                                color: $green3;
                            }
                        }
                        
                    }
                    .__left{
                        display: flex;
                        align-items: center;
                        button.button__remember{
                            width: 20px;
                            height: 20px;
                            border-radius: 4px;
                            border: none;
                            padding: 0;
                            margin-right: 0.5rem;
                            background-color: $green3;
                            transition: all 0.1s;
                            position: relative;
                            &:hover{
                                background-color: $blue1;
                            }
                            &::before{
                                display: none;
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-image: url('../../images/check-single.svg');
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                            &.__active{
                                &::before{
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .login__item{
                    margin-bottom: 1.25rem;
                    input.input{
                        height: 56px;
                        border-radius: 12px;
                        font-size: 16px;
                        font-weight: 400;
                        border: 1px solid #E8E9EB;
                        transition: all 0.1s;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05);
                        &::placeholder{
                            color: $dark4;
                        }
                        &:focus{
                            border-color: $blue_main;
                            box-shadow: 0 0 0 0.25rem #0085FF30;
                        }
                    }
                }
                
            }
        }
    }
    &.__register{
        background: linear-gradient(143.01deg, #0E2A47e7 19.49%, #301934e7 50.13%, #000822cb 83.44%), url('../../images/register-bg.jpg');
        background-position: bottom right;
        background-size: 50%;
        .login__side{
            .login__cont{
                width: 600px;
            }
            h1{
                margin-bottom: 1.5rem;
            }
            button.register__new{
                background-color: #fff;
                border: 1px solid $stroke1;
                font-size: 14px;
                color: $dark1;
                margin-bottom: 1.5rem;
                box-shadow: 0px 2px 4px -2px rgba(27, 46, 94, 0.08);
                border-radius: 6px;
                span{
                    margin-left: 0.75rem;
                    color: $blue1;
                    font-weight: 600;
                    transition: all 0.1s;
                }
                transition: all 0.1s;
                &:hover{
                    background-color: $blue1;
                    color: #fff;
                    span{
                        color: #fff;
                    }
                }
            }
            .register__form__wrap{
                background-color: #F8F7F5;
                border: 1px solid #E8E8E8;
                width: 100%;
                padding: 1rem;
                padding-top: 2rem;
                padding-bottom: 1rem;
                border-radius: 12px;
                height: 600px;
                overflow-y: scroll;
                
                .register__form{
                    position: relative;
                    padding-bottom: 0rem;
                    .register__error{
                        position: sticky;
                        width: 100%;
                        padding-bottom: 1rem;
                        // padding: 1rem;
                        left: 0;
                        top: -1rem;
                        z-index: 111;
                        p{
                            background-color: $orange1;
                            padding: 1rem;
                            color: #fff;
                            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.06);
                            border-radius: 8px;
                        }
                    }
                    .submit__wrap{
                        position: sticky;
                        width: 100%;
                        padding-top: 1rem;
                        // padding: 1rem;
                        left: 0;
                        bottom: 0;
                        button.register__submit{
                            // position: fixed;
                            width: 100%;
                            z-index: 999;
                            background-color: $green3;
                            border: 1px solid $green2;
                            color: #fff;
                            font-weight: 500;
                            font-size: 16px;
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.06);
                            border-radius: 8px;
                        }
                    }
                    
                    h4{
                        font-weight: 500;
                        font-size: 13px;
                        color: $dark4;
                        margin-bottom: 0.25rem;
                        &.__alt{
                            margin-top: 2.5rem;
                        }
                    }
                    h3{
                        font-weight: 500;
                        font-size: 18px;
                        color: $dark2;
                        margin-bottom: 1rem;
                    }
                    .column{
                        .login__item{
                            margin-bottom: 0;
                        }
                    }
                    .login__item{
                        margin-bottom: 1.25rem;
                        input.input{
                            height: 56px;
                            border-radius: 12px;
                            font-size: 16px;
                            font-weight: 400;
                            border: 1px solid #E8E9EB;
                            transition: all 0.1s;
                            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05);
                            &::placeholder{
                                color: $dark4;
                            }
                            &:focus{
                                border-color: $blue_main;
                                box-shadow: 0 0 0 0.25rem #0085FF30;
                            }
                        }
                    }
                    .register__button__wrap{
                        button.button__number{
                            display: block;
                            text-align: left;
                            padding: 1.25rem;
                            border: 1px solid #E8E9EB;
                            width: 100%;
                            height: max-content;
                            font-weight: 500;
                            font-size: 16px;
                            border-radius: 12px;
                            transition: all 0.1s;
                            &.__active{
                                border: solid 3px $green4;
                            }
                        }
                    }
                }
            }
        }
        
    }
}